import React from 'react'
import Logo from '../../svg/Logo'
import { Typography } from '@mui/material'

function SadlerHeader() {
    return (
        <div>
            <Logo className="text-2xl text-primary" />
            <Typography variant='h6' className='!text-white !mt-6'>
                Thank you for visiting our pre-sales page! At Sadler & Frost, we are committed to understanding your unique business needs and providing tailored accountancy services that help your business thrive.
            </Typography>
        </div>
    )
}

export default SadlerHeader