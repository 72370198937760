import Providers from "./contexts/Providers";
import { Routes, Route } from "react-router-dom";
import CompanyForm from "./pages/company_form/CompanyForm";
import LandingPage from "./pages/landing_page/LandingPage";
import Thankyou from "./pages/thankyou/Thankyou";
import { Navigate } from "react-router-dom";

function App() {
  return (
    <Providers>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/company-form' element={<CompanyForm />} />
        <Route path='/thankyou' element={<Thankyou />} />
        <Route path='/*' element={<Navigate to="/" />} />
      </Routes>
    </Providers>
  );
}

export default App;
