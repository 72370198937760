const formControlLabel = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginInlineStart: theme.spacing(-2),
        fontSize: '22px'
      }),
      label: {
        '&, &.Mui-disabled': {
          // color: 'var(--mui-palette-text-primary)',
          fontSize: '14px',
        },
        '&.Mui-disabled': {
          opacity: 0.45
        }
      }
    }
  }
}

export default formControlLabel
