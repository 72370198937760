let price = {
    "Sole Trader": {
        "Up to £90k": {
            "accounting_transactions_per_month": {
                "Up to 20": 30,
                "20-40": 40,
                "40-80": 50,
                "80-120": 55,
                "120+": 60,
                "I don't know": 30
            },
            "vat_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 55,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 50
            },
            "payroll_how_many_employee": 10,
            "pension_how_many_employee": 25
        },
        "£90-200k": {
            "accounting_transactions_per_month": {
                "Up to 20": 35,
                "20-40": 45,
                "40-80": 55,
                "80-120": 60,
                "120+": 65,
                "I don't know": 35
            },
            "vat_transactions_per_month": {
                "Up to 20": 55,
                "20-40": 60,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 55
            },
            "payroll_how_many_employee": 15,
            "pension_how_many_employee": 30
        },
        "£200-500k": {
            "accounting_transactions_per_month": {
                "Up to 20": 40,
                "20-40": 50,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 40
            },
            "vat_transactions_per_month": {
                "Up to 20": 60,
                "20-40": 65,
                "40-80": 70,
                "80-120": 75,
                "120+": 80,
                "I don't know": 60
            },
            "payroll_how_many_employee": 20,
            "pension_how_many_employee": 35
        },
        "£500k-1m": {
            "accounting_transactions_per_month": {
                "Up to 20": 45,
                "20-40": 55,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 45
            },
            "vat_transactions_per_month": {
                "Up to 20": 65,
                "20-40": 70,
                "40-80": 75,
                "80-120": 80,
                "120+": 85,
                "I don't know": 65
            },
            "payroll_how_many_employee": 25,
            "pension_how_many_employee": 40
        },
        "£1m+": {
            "accounting_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 60,
                "40-80": 70,
                "80-120": 75,
                "120+": 85,
                "I don't know": 50
            },
            "vat_transactions_per_month": {
                "Up to 20": 70,
                "20-40": 75,
                "40-80": 80,
                "80-120": 85,
                "120+": 90,
                "I don't know": 70
            },
            "payroll_how_many_employee": 30,
            "pension_how_many_employee": 45
        }
    },
    "Limited Company": {
        "Up to £90k": {
            "accounting_transactions_per_month": {
                "Up to 20": 100,
                "20-40": 105,
                "40-80": 110,
                "80-120": 115,
                "120+": 120,
                "I don't know": 100
            },
            "vat_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 55,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 50
            },
            "payroll_how_many_employee": 10,
            "pension_how_many_employee": 25
        },
        "£90-200k": {
            "accounting_transactions_per_month": {
                "Up to 20": 105,
                "20-40": 110,
                "40-80": 115,
                "80-120": 120,
                "120+": 125,
                "I don't know": 105
            },
            "vat_transactions_per_month": {
                "Up to 20": 55,
                "20-40": 60,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 55
            },
            "payroll_how_many_employee": 15,
            "pension_how_many_employee": 30
        },
        "£200-500k": {
            "accounting_transactions_per_month": {
                "Up to 20": 110,
                "20-40": 115,
                "40-80": 120,
                "80-120": 125,
                "120+": 130,
                "I don't know": 110
            },
            "vat_transactions_per_month": {
                "Up to 20": 60,
                "20-40": 65,
                "40-80": 70,
                "80-120": 75,
                "120+": 80,
                "I don't know": 60
            },
            "payroll_how_many_employee": 20,
            "pension_how_many_employee": 35
        },
        "£500k-1m": {
            "accounting_transactions_per_month": {
                "Up to 20": 115,
                "20-40": 120,
                "40-80": 125,
                "80-120": 130,
                "120+": 135,
                "I don't know": 115
            },
            "vat_transactions_per_month": {
                "Up to 20": 65,
                "20-40": 70,
                "40-80": 75,
                "80-120": 80,
                "120+": 85,
                "I don't know": 65
            },
            "payroll_how_many_employee": 25,
            "pension_how_many_employee": 40
        },
        "£1m+": {
            "accounting_transactions_per_month": {
                "Up to 20": 120,
                "20-40": 125,
                "40-80": 130,
                "80-120": 135,
                "120+": 140,
                "I don't know": 120
            },
            "vat_transactions_per_month": {
                "Up to 20": 70,
                "20-40": 75,
                "40-80": 80,
                "80-120": 85,
                "120+": 90,
                "I don't know": 70
            },
            "payroll_how_many_employee": 30,
            "pension_how_many_employee": 45
        }
    }
}

const calculatePrice = (company) => {
    try {
        let pricePlan = price[company.business_type][company.annual_income]

        let totoalPrice = 0
        if (company.interested_service.accounting_and_bookkeeping) {
            totoalPrice += pricePlan['accounting_transactions_per_month'][company.accounting_transactions_per_month]
        }

        if (company.interested_service.vat_returns) {
            totoalPrice += pricePlan['vat_transactions_per_month'][company.vat_transactions_per_month]
        }

        if (company.interested_service.payroll) {
            totoalPrice += (pricePlan['payroll_how_many_employee'] * company.payroll_how_many_employee)
        }

        if (company.interested_service.pension) {
            totoalPrice += (pricePlan['pension_how_many_employee'] * company.pension_how_many_employee)
        }
        return totoalPrice;
    } catch (err) {
        return 0
    }
}

export default calculatePrice