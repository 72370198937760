import React, { useState, createContext } from 'react'
import themeConfig from '../../config/themeConfig'
import primaryColorConfig from '../../config/primaryColorConfig'


export const SettingsContext = createContext(null)

export const SettingsProvider = (props) => {

    const initialSettings = {
        mode: themeConfig.mode,
        systemMode: themeConfig.mode == 'system' ? 'light' : themeConfig.mode,
        primaryColor: primaryColorConfig[4].main,
        skin: themeConfig.skin,
    }

    const updatedInitialSettings = {
        ...initialSettings,
        mode: props.mode || themeConfig.mode
    }

    // State
    const [_settingsState, _updateSettingsState] = useState(updatedInitialSettings);

    const updateSettings = (settings) => {
        _updateSettingsState(prev => {
            const newSetting = { ...prev, ...settings }
            return newSetting;
        })
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: _settingsState,
                updateSettings,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    )
}
