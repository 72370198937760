import { useEffect } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'


const step1Schema = v.object({
    business_type: v.pipe(v.string(), v.minLength(1)),
});

function CompanyFormStep1({ onSubmit, handleBack, title, changeBusinessType }) {

    // Hooks
    const { company } = useCompany()

    const {
        control: step1Control,
        handleSubmit: handleStep1Submit,
        formState: { errors: step1Errors },
        watch
    } = useForm({
        resolver: valibotResolver(step1Schema),
        defaultValues: {
            business_type: company.business_type
        }
    })

    const businessType = watch('business_type', 'Sole Trader')

    useEffect(() => {
        changeBusinessType(businessType)
    }, [businessType])

    return (
        <form key={0} onSubmit={handleStep1Submit(onSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <Controller
                        name='business_type'
                        control={step1Control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                <FormControlLabel value='Limited Company' control={<Radio />} label='Limited Company' />
                                <FormControlLabel value='Sole Trader' control={<Radio />} label='Sole Trader' />
                                <FormControlLabel value='Dormant' control={<Radio />} label='Dormant Company' />
                                <FormControlLabel value='SPV' control={<Radio />} label='Special Purpose Vehicle (SPV)' />
                            </RadioGroup>
                        )}
                    />
                    {step1Errors.business_type && <FormHelperText error>This field is required.</FormHelperText>}
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompanyFormStep1