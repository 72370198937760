import axios from "axios";

const api = axios.create({
    baseURL: "https://api.sadlerandfrost.com/api"
})


export const sendQuoteDataReq = async (body) => {
    const response = await api.post(`/sadler/calculate_quote/insert_quote`, body)
    return response.data
}