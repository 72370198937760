import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// Component imports
import SadlerHeader from '../../component/shared/sadler_header/SadlerHeader'

// Hook Imports
import { useCompany, updateComapny } from '../../hooks/useCompany'
import { useNavigate } from 'react-router-dom'

function Thankyou() {

    // Hooks
    const { company, updateComapny } = useCompany()

    const navigate = useNavigate()

    const handleGoHome = () => {
        updateComapny({
            business_type: '',
            annual_income: '',
            interested_service: {
                accounting_and_bookkeeping: false,
                vat_returns: false,
                payroll: false,
                pension: false,
                other: false
            },
            accounting_transactions_per_month: '',
            vat_transactions_per_month: '',
            payroll_how_many_employee: '',
            pension_how_many_employee: '',
            other_interested: '',
            email: '',
            phone_number: '',
            name: '',
            total_price: 0
        })
        navigate('/')
    }

    return (
        <div className='px-4'>
            <Grid container justifyContent="center">
                <Grid item sm={12} md={6}>
                    <div className='mt-14 px-4 text-center mb-12'>
                        <SadlerHeader />
                    </div>
                    <Card>
                        <CardContent className='text-center'>
                            <Typography variant='h1' className='!text-textPrimary !font-extrabold'>
                                Thank You!
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent className='text-center'>
                            <Typography variant='h5' className='!text-textSecondary'>
                                Based on the information you provided, we can offer our services starting from
                            </Typography>
                            <Typography variant='h5' className='!text-textSecondary'>
                                <span className='text-textPrimary font-bold'><span className='text-2xl'>£{company.total_price}</span> + VAT per month*</span>
                            </Typography>
                            <Typography variant='h5' className='!text-textSecondary !my-8'>
                                Our sales team will contact you shortly to provide a more detailed quote and answer any questions you may have. We appreciate your interest in our services and look forward to assisting you further.
                            </Typography>
                            <Typography variant='subtitle2' className='!text-textSecondary !my-'>
                                *Prices are inclusive of software bundles (such as Xero and Dext) but exclude any add-ons that may be required for your onboarding.
                            </Typography>
                        </CardContent>
                    </Card>
                    <div className='text-center mt-8 cursor-pointer' onClick={handleGoHome}>
                        <span className='bg-backgroundPaper rounded-sm py-3 px-4 text-textPrimary font-bold text-lg underline'>
                            Go to homepage
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Thankyou