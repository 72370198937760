
import React from 'react'

// Mui Imports

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// Component Imports
import LogoBlue from '../../component/svg/LogoBlue'
import FollowUsFooter from './follow_us_footer/FollowUsFooter'
import ContactUsFooter from './contact_us_footer/ContactUsFooter'

function Footer() {
    return (
        <div className="py-8 bg-backgroundPaper">
            <Grid container justifyContent="center">
                <Grid item xs={12} md={4}>
                    <div className='flex flex-col items-center'>
                        <LogoBlue />
                        <Typography className='!mt-2 !font-bold' variant='h6'>@{new Date().getFullYear()} All Rights Reserved</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ContactUsFooter />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FollowUsFooter />
                </Grid>

            </Grid>
        </div>
    )
}

export default Footer