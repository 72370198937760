import typography from './typography';
import button from './button';
import tooltip from './tooltip';
import chip from './chip'
import card from './card'
import avatar from './avatar';
import pagination from './pagination'
import tabs from './tabs'
import switchOverrides from './switch'
import accordion from './accordion'
import menu from './menu'
import formControlLabel from './form-control-label'


const overrides = (skin) => {
    return Object.assign(
        {},
        button,
        tooltip,
        chip,
        card(),
        avatar,
        formControlLabel,
        pagination,
        tabs,
        accordion(skin),
        typography,
        switchOverrides,
        menu(skin),
    )
}

export default overrides;