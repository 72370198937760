import React, { createContext, useState } from 'react'

export const CompanyContext = createContext(null)

export const CompanyProvider = (props) => {

    const initialCompany = {
        request_type: '',
        business_type: 'Limited Company',
        company_name: '',
        annual_income: '',
        interested_service: {
            accounting_and_bookkeeping: false,
            vat_returns: false,
            payroll: false,
            pension: false,
            ecommerce_accounting: false,
            other: false
        },
        accounting_option: '',
        payroll_option: '',
        ecommerce_option: '',
        other_interested: '',
        email: '',
        phone_number: '',
        referral_id: '',
        name: '',
        total_price: 0
    }

    const [_companyState, _updateCompanyState] = useState(initialCompany)

    const updateComapny = (company) => {
        _updateCompanyState(prev => {
            const newCompany = { ...prev, ...company }
            return newCompany
        })
    }

    return (
        <CompanyContext.Provider
            value={{
                company: _companyState,
                updateComapny
            }}
        >
            {props.children}
        </CompanyContext.Provider>
    )

}