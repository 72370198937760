// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

function CompanyFormStep4({ onSubmit, handleBack, title }) {

    // Hooks
    const { company } = useCompany()

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                <div className='mt-4'>
                    <Typography variant='h4'>Request Type</Typography>
                    <Typography>{company.request_type}</Typography>
                </div>
                <div className='mt-4'>
                    <Typography variant='h4'>Business Type</Typography>
                    <Typography>{company.business_type}</Typography>
                </div>
                {company.annual_income && (
                    <div className='mt-2'>
                        <Typography variant='h4'>Annual Turnover</Typography>
                        <Typography>{company.annual_income}</Typography>
                    </div>
                )}
                {(company.interested_service.accounting_and_bookkeeping ||
                    company.interested_service.vat_returns ||
                    company.interested_service.payroll ||
                    company.interested_service.pension ||
                    company.interested_service.ecommerce_accounting ||
                    company.interested_service.other) &&
                    <div className='mt-2'>
                        <Typography variant='h4'>Services</Typography>
                        {company.interested_service.accounting_and_bookkeeping && <Typography>Accounting & Bookkeeping</Typography>}
                        {company.interested_service.vat_returns && <Typography>Vat Returns</Typography>}
                        {company.interested_service.payroll && <Typography>Payroll</Typography>}
                        {company.interested_service.pension && <Typography>Pension</Typography>}
                        {company.interested_service.ecommerce_accounting && <Typography>E-commerce Accunting</Typography>}
                        {company.interested_service.other && <Typography>Other</Typography>}
                    </div>
                }
            </Grid>
            <Grid item xs={12} className='flex justify-between'>
                <Button
                    variant='tonal'
                    onClick={handleBack}
                    color='secondary'
                    startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                >
                    Back
                </Button>
                <Button variant='contained' onClick={() => onSubmit()} endIcon={<Icon icon='tabler-check' />}>
                    Next
                </Button>
            </Grid>
        </Grid>
    )
}

export default CompanyFormStep4