// React Imports
import { useEffect, useState } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

// Third-party Imports
import { useNavigate } from 'react-router-dom'

// Hook Imports
import { useCompany } from '../../hooks/useCompany'

// Component imports
import SadlerHeader from '../../component/shared/sadler_header/SadlerHeader'
import CompanyFormStep5 from './step5/CompanyFormStep5'
import CompanyFormStep4 from './step4/CompanyFormStep4'
import CompanyFormStep3 from './step3/CompanyFormStep3'
import CompanyFormStep2 from './step2/CompanyFormStep2'
import CompanyFormStep1 from './step1/CompanyFormStep1'
import StepperWrapper from '../../styles/stepper'
import StepperCustomDot from '../../component/stepper-dot'
import SoleStep3 from './sole_step3/SoleStep3'
import LimitedStep3 from './limited_step3/LimitedStep3'
import SpecialStep3 from './special_step3/SpecialStep3'
import DormantStep3 from './dormant_step3/DormantStep3'

// Vars
const normalSteps = [
    {
        title: 'What is your Business Type?'
    },
    {
        title: 'What is your Annual Turnover?'
    },
    {
        title: 'What Services are you interested in?'
    },
    {
        title: 'Your Summary'
    },
    {
        title: 'Your Details'
    }
]

const dormantSteps = [
    {
        title: 'What is your Business Type?'
    },
    {
        title: 'What Services are you interested in?'
    },
    {
        title: 'Your Summary'
    },
    {
        title: 'Your Details'
    }
]

function CompanyForm() {
    // States    
    const [activeStep, setActiveStep] = useState(0)
    const [steps, setSteps] = useState(normalSteps)

    // Hooks
    const { company, updateComapny } = useCompany()
    const navigate = useNavigate();

    const onSubmit = (data) => {
        updateComapny(data)
        if (company.business_type == 'Dormant') {
            if (activeStep < 3) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            } else if (activeStep == 3) {
                navigate('/thankyou')
                setActiveStep(0)
            }
        } else {
            if (activeStep < 4) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            } else if (activeStep == 4) {
                navigate('/thankyou')
                setActiveStep(0)
            }
        }
    }

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevActiveStep => prevActiveStep - 1)
        } else if (activeStep == 0) {
            navigate('/')
        }
    }

    const changeBusinessType = (type) => {
        if (type == 'Dormant') {
            setSteps(dormantSteps)
        } else {
            setSteps(normalSteps)
        }
        updateComapny({
            annual_income: '',
            interested_service: {
                accounting_and_bookkeeping: false,
                vat_returns: false,
                payroll: false,
                pension: false,
                ecommerce_accounting: false,
                other: false
            },
            accounting_option: '',
            payroll_option: '',
            ecommerce_option: '',
            other_interested: '',
            total_price: 0
        })
    }

    const renderStepContent = activeStep => {
        if (company.business_type == 'Dormant') {
            switch (activeStep) {
                case 0:
                    return (
                        <CompanyFormStep1 onSubmit={onSubmit} handleBack={handleBack} changeBusinessType={changeBusinessType} title={steps[0].title} />
                    )
                case 1:
                    return (
                        <DormantStep3 onSubmit={onSubmit} handleBack={handleBack} title={steps[1].title} />
                    )
                case 2:
                    return (
                        <CompanyFormStep4 onSubmit={onSubmit} handleBack={handleBack} title={steps[2].title} />
                    )
                case 3:
                    return (
                        <CompanyFormStep5 onSubmit={onSubmit} handleBack={handleBack} title={steps[3].title} />
                    )
            }
        } else {
            switch (activeStep) {
                case 0:
                    return (
                        <CompanyFormStep1 onSubmit={onSubmit} handleBack={handleBack} changeBusinessType={changeBusinessType} title={steps[0].title} />
                    )
                case 1:
                    return (
                        <CompanyFormStep2 onSubmit={onSubmit} handleBack={handleBack} title={steps[1].title} />
                    )
                case 2:
                    if (company.business_type == 'Sole Trader') {
                        return (<SoleStep3 onSubmit={onSubmit} handleBack={handleBack} title={steps[2].title} />)
                    } else if (company.business_type == 'Limited Company') {
                        return (<LimitedStep3 onSubmit={onSubmit} handleBack={handleBack} title={steps[2].title} />)
                    } else if (company.business_type == 'SPV') {
                        return (<SpecialStep3 onSubmit={onSubmit} handleBack={handleBack} title={steps[2].title} />)
                    }

                case 3:
                    return (
                        <CompanyFormStep4 onSubmit={onSubmit} handleBack={handleBack} title={steps[3].title} />
                    )
                case 4:
                    return (
                        <CompanyFormStep5 onSubmit={onSubmit} handleBack={handleBack} title={steps[4].title} />
                    )
            }
        }
    }

    return (
        <div className='px-4'>
            <Grid container justifyContent="center">
                <Grid item sm={12} md={6}>
                    <div className='mt-14 px-4 text-center mb-12'>
                        <SadlerHeader />
                    </div>
                    <Card>
                        <CardContent>
                            {/* <Typography variant='h5' className='!text-textPrimary !font-bold'>
                                {steps[activeStep]?.title}
                            </Typography> */}
                            <StepperWrapper>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={index} className='max-md:mbe-5'>
                                                <StepLabel StepIconComponent={StepperCustomDot}>
                                                    <div className='step-label'>
                                                        <Typography className='step-number'>{`${index + 1}`}</Typography>
                                                    </div>
                                                </StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </StepperWrapper>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            {renderStepContent(activeStep)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default CompanyForm