import { useState, useEffect } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse';

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

const step3Schema = v.pipe(
    v.object({
        interested_service: v.pipe(
            v.object({
                accounting_and_bookkeeping: v.boolean(),
                vat_returns: v.boolean(),
                payroll: v.boolean(),
                pension: v.boolean(),
                other: v.boolean(),
            }),
        ),
        accounting_transactions_per_month: v.pipe(
            v.string()
        ),
        vat_transactions_per_month: v.pipe(
            v.string()
        ),
        payroll_how_many_employee: v.pipe(
            v.string()
        ),
        pension_how_many_employee: v.pipe(
            v.string()
        ),
        other_interested: v.pipe(
            v.string()
        )
    }),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['accounting_transactions_per_month'],
            ],
            (input) => {
                if (input.interested_service.accounting_and_bookkeeping) {
                    if (input.accounting_transactions_per_month) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'accounting_transactions_per_month',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['vat_transactions_per_month'],
            ],
            (input) => {
                if (input.interested_service.vat_returns) {
                    if (input.vat_transactions_per_month) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'vat_transactions_per_month',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['payroll_how_many_employee'],
            ],
            (input) => {
                if (input.interested_service.payroll) {
                    if (input.payroll_how_many_employee) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'payroll_how_many_employee',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['pension_how_many_employee'],
            ],
            (input) => {
                if (input.interested_service.pension) {
                    if (input.pension_how_many_employee) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'pension_how_many_employee',
        ]
    ),
)

function CompanyFormStep3({ onSubmit, handleBack, title }) {

    // State
    const [showVatTransactions, setShowVatTransactions] = useState(false)
    const [showAccountingTransactions, setShowAccountingTransactions] = useState(false)
    const [showPensionHowManyEmployee, setShowPensionHowManyEmployee] = useState(false)
    const [showPayrollHowManyEmployee, setShowPayrollHowManyEmployee] = useState(false)
    const [showOther, setShowOther] = useState(false)

    // Hooks
    const { company } = useCompany()

    const {
        control: step3Control,
        handleSubmit: handleStep3Submit,
        formState: { errors: step3Errors },
        watch
    } = useForm({
        resolver: valibotResolver(step3Schema),
        defaultValues: {
            interested_service: company.interested_service,
            accounting_transactions_per_month: company.accounting_transactions_per_month,
            vat_transactions_per_month: company.vat_transactions_per_month,
            payroll_how_many_employee: company.payroll_how_many_employee,
            pension_how_many_employee: company.pension_how_many_employee,
            other_interested: company.other_interested
        }
    })

    useEffect(() => {
        if (
            watch('interested_service.accounting_and_bookkeeping')
        ) {
            setShowAccountingTransactions(true)
        } else {
            setShowAccountingTransactions(false)
        }

    }, [watch('interested_service.accounting_and_bookkeeping')])

    useEffect(() => {
        if (
            watch('interested_service.vat_returns')
        ) {
            setShowVatTransactions(true)
        } else {
            setShowVatTransactions(false)
        }

    }, [watch('interested_service.vat_returns')])

    useEffect(() => {
        if (
            watch('interested_service.payroll')
        ) {
            setShowPayrollHowManyEmployee(true)
        } else {
            setShowPayrollHowManyEmployee(false)
        }

    }, [watch('interested_service.payroll')])

    useEffect(() => {
        if (
            watch('interested_service.pension')
        ) {
            setShowPensionHowManyEmployee(true)
        } else {
            setShowPensionHowManyEmployee(false)
        }

    }, [watch('interested_service.pension')])

    useEffect(() => {
        if (
            watch('interested_service.other')
        ) {
            setShowOther(true)
        } else {
            setShowOther(false)
        }

    }, [watch('interested_service.other')])


    return (
        <form key={0} onSubmit={handleStep3Submit(onSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12} >
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <div className='flex flex-col mt-2'>
                        <Controller
                            name='interested_service.accounting_and_bookkeeping'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} />} label={<span className='font-bold'>Do you need Accounting & Bookkeeping Services?</span>} />
                                    <Collapse in={showAccountingTransactions}>
                                        <div className='ml-8'>
                                            <Typography variant='h5' className='!font-bold'>
                                                How many transactions do you have per month?
                                            </Typography>
                                            <Controller
                                                name='accounting_transactions_per_month'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                                        <FormControlLabel value='Up to 20' control={<Radio />} label='Up to 20 tx' />
                                                        <FormControlLabel value='20-40' control={<Radio />} label='20-40 tx' />
                                                        <FormControlLabel value='40-80' control={<Radio />} label='40-80 tx' />
                                                        <FormControlLabel value='80-120' control={<Radio />} label='80-120 tx' />
                                                        <FormControlLabel value='120+' control={<Radio />} label='120+ tx' />
                                                        <FormControlLabel value="I don't know" control={<Radio />} label="I don't know" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            {step3Errors.accounting_transactions_per_month && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </div>
                                    </Collapse>
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.vat_returns'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} />} label={<span className='font-bold'>Do you need VAT Returns?</span>} />
                                    <Collapse in={showVatTransactions}>
                                        <div className='ml-8'>
                                            <Typography variant='h5' className='!font-bold'>
                                                How many transactions do you have per month?
                                            </Typography>
                                            <Controller
                                                name='vat_transactions_per_month'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                                        <FormControlLabel value='Up to 20' control={<Radio />} label='Up to 20 tx' />
                                                        <FormControlLabel value='20-40' control={<Radio />} label='20-40 tx' />
                                                        <FormControlLabel value='40-80' control={<Radio />} label='40-80 tx' />
                                                        <FormControlLabel value='80-120' control={<Radio />} label='80-120 tx' />
                                                        <FormControlLabel value='120+' control={<Radio />} label='120+ tx' />
                                                        <FormControlLabel value="I don't know" control={<Radio />} label="I don't know" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            {step3Errors.vat_transactions_per_month && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </div>
                                    </Collapse>
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.payroll'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} />} label={<span className='font-bold'>Do you need Payroll?</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showPayrollHowManyEmployee}>
                                            <Controller
                                                name='payroll_how_many_employee'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CustomTextField
                                                        {...field}
                                                        fullWidth
                                                        type="number"
                                                        label='How many employees (including Directors)?'
                                                    />
                                                )}
                                            />
                                            {step3Errors.payroll_how_many_employee && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                        />
                        <Controller
                            name='interested_service.pension'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} />} label={<span className='font-bold'>Do you need Pension?</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showPensionHowManyEmployee}>
                                            <Controller
                                                name='pension_how_many_employee'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CustomTextField
                                                        {...field}
                                                        fullWidth
                                                        type="number"
                                                        label='How many employees (including Directors)?'
                                                    />
                                                )}
                                            />
                                            {step3Errors.pension_how_many_employee && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                        />
                        <Controller
                            name='interested_service.other'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} />} label={<span className='font-bold'>Do you need Other Services? Please specify.</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showOther}>
                                            <Controller
                                                name='other_interested'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CustomTextField
                                                        {...field}
                                                        variant='text'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                )}
                                            />
                                            {step3Errors.how_many_employee && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>

                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompanyFormStep3