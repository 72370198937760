// Component Imports
import { Typography } from "@mui/material"
import Footer from "./footer/Footer"

function MainLayout(props) {
    return (
        <div className="bg-[#0b4a8f] flex flex-col min-h-screen justify-between">
            <div className="bg-[url('/public/sf-bg-1.png')] flex flex-col !pb-28">
                {props.children}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )

}

export default MainLayout