import React from 'react'

// Mui Imports
import Typography from '@mui/material/Typography'
import { Icon } from '@iconify/react/dist/iconify.js'

import { Link } from 'react-router-dom';

function ContactUsFooter() {

  const handleOpenMail = () => {
    window.location.href = "mailto:hello@sadlerandfrost.com"
  }

  const openInNewTab = () => {
    const url = "https://www.google.com/maps/place/Sadler+%26+Frost/@51.522631,-0.711236,14z/data=!4m6!3m5!1s0x487663b11716257f:0xae7b544f7380293c!8m2!3d51.5226311!4d-0.7112359!16s%2Fg%2F11r2qy27cw?hl=tr-TR&entry=ttu"
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleOpenPhone = () => {
    window.location.href = "tel:+442034884747"
  }

  return (
    <div className='px-4'>
      <Typography variant='h5' className='!font-bold !mb-4  underline'>Contact Us</Typography>
      <div className='flex flex-col justify-start items-start gap-2'>
        <div className='flex items-center gap-2 text-gray-600 cursor-pointer' onClick={handleOpenMail}>
          <Icon icon='streamline:mail-send-email-message-solid' />
          <Typography>hello@sadlerandfrost.com</Typography>
        </div>
        <div className='flex items-center gap-2 text-gray-600 cursor-pointer' onClick={openInNewTab}>
          <Icon icon='streamline:location-pin-3-solid' />
          <Typography>Clyde House, Reform Road, Maidenhead, SL6 8BY United Kingdom</Typography>
        </div>
        <div className='flex items-center gap-2 text-gray-600 cursor-pointer' onClick={handleOpenPhone}>
          <Icon icon='streamline:phone-solid' />
          <Typography>+44 1628 232402</Typography>
        </div>
      </div>
    </div>
  )
}

export default ContactUsFooter