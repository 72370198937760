// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'

const step2Schema = v.object({
    annual_income: v.pipe(v.string(), v.minLength(1)),
});

function CompanyFormStep2({ onSubmit, handleBack, title }) {

    // Hooks
    const { company } = useCompany()

    const {
        control: step2Control,
        handleSubmit: handleStep2Submit,
        formState: { errors: step2Errors }
    } = useForm({
        resolver: valibotResolver(step2Schema),
        defaultValues: {
            annual_income: company.annual_income
        }
    })

    return (
        <form key={2} onSubmit={handleStep2Submit(onSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <Controller
                        name='annual_income'
                        control={step2Control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                <FormControlLabel value='Up to £90k' control={<Radio />} label='Up to £90,000' />
                                <FormControlLabel value='£90-£500k' control={<Radio />} label='£90,000 - £500,000' />
                                <FormControlLabel value='£500k-£1m' control={<Radio />} label='£500,000 - £1 million' />
                                <FormControlLabel value='£1m+' control={<Radio />} label='Over £1 million' />
                            </RadioGroup>
                        )}
                    />
                    {step2Errors.annual_income && <FormHelperText error>This field is required.</FormHelperText>}
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompanyFormStep2