import React from 'react'
import { SettingsProvider } from './setting/SettingsContext'
import { CompanyProvider } from './company/CompanyContext'
import ThemProvider from './theme/ThemProvider'
import MainLayout from '../layout/MainLayout'
import 'react-phone-input-2/lib/style.css'

function Providers({ children }) {
    return (
        <SettingsProvider mode="system">
            <CompanyProvider>
                <ThemProvider>
                    <MainLayout>
                        {children}
                    </MainLayout>
                </ThemProvider>
            </CompanyProvider>
        </SettingsProvider>
    )
}

export default Providers