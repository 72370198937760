import { useContext } from 'react'

import { CompanyContext } from '../contexts/company/CompanyContext'

export const useCompany = () => {
    const context = useContext(CompanyContext)

    if (!context) {
        throw new Error('useSettingsContext must be used within a SettingsProvider')
    }

    return context
}
