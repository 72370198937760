import React from 'react'

// Mui Imports
import Typography from '@mui/material/Typography'

// Component Imports
import { Icon } from '@iconify/react/dist/iconify.js'

const apps = [
  {
    icon: 'ri:twitter-x-fill',
    link: 'https://x.com/sadlerandfrost'
  },
  {
    icon: 'mdi:youtube',
    link: 'https://www.youtube.com/@sadlerandfrost'
  },
  {
    icon: 'mdi:linkedin',
    link: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEHJYlUpsuKugAAAY_AS5VQB909WaCriNWjlBH8Sc6YGkEISfnmxvwezTC45DKNhL5mFGQzl2SbV4XtbOKjyadj4Vm0xYd2viY1WHSKPtCv0LYu9KxQ1N0S67CJ7pkDhkb1Qxc=&original_referer=https://sadlerandfrost.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fsadlerandfrost%2F'
  },
  {
    icon: 'mdi:instagram',
    link: 'https://www.instagram.com/sadlerandfrost/'
  },
  {
    icon: 'mdi:facebook',
    link: 'https://www.facebook.com/people/Sadler-and-Frost/61553605996068/'
  },
]

function FollowUsFooter() {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div>
      <Typography variant='h5' className='!font-bold !mb-4 underline'>Follow Us</Typography>
      <div className='flex gap-5'>
        {apps.map((app, index) => (
          <div
            key={index}
            className='cursor-pointer border-[0.5px] rounded-full pt-[6px] px-[6px] border-[#0b4a8f]  text-[#0b4a8f] hover:text-white hover:bg-[#0b4a8f] duration-300'
            onClick={() => openInNewTab(app.link)}>
            <Icon icon={app.icon} fontSize={32} />
          </div>
        ))}
      </div>

    </div>
  )
}

export default FollowUsFooter