import React, { Children, useMemo } from 'react'
import {
    experimental_extendTheme as extendTheme,
    Experimental_CssVarsProvider as CssVarsProvider,
    lighten,
    darken
} from '@mui/material/styles';
import { useSettings } from '../../hooks/useSetting';
import { deepmerge } from '@mui/utils'
import defaultCoreTheme from '../../config/theme'
import CssBaseline from '@mui/material/CssBaseline'
import ModeChanger from './ModeChanger';

const ThemProvider = (props) => {

    const { settings } = useSettings();

    let currentMode = settings.mode == 'system' ? settings.systemMode : settings.mode

    const theme = useMemo(() => {
        const newColorScheme = {
            colorSchemes: {
                light: {
                    palette: {
                        primary: {
                            main: settings.primaryColor,
                            light: lighten(settings.primaryColor, 0.2),
                            dark: darken(settings.primaryColor, 0.1)
                        }
                    }
                },
                dark: {
                    palette: {
                        primary: {
                            main: settings.primaryColor,
                            light: lighten(settings.primaryColor, 0.2),
                            dark: darken(settings.primaryColor, 0.1)
                        }
                    }
                }
            }
        }

        const coreTheme = deepmerge(defaultCoreTheme(settings, currentMode, 'ltr'), newColorScheme)
        return extendTheme(coreTheme);
    }, [settings])

    return (
        <CssVarsProvider theme={theme}>
            <>
                <ModeChanger />
                <CssBaseline />
                {props.children}
            </>

        </CssVarsProvider>
    )
}

export default ThemProvider