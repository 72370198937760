import { useState, useEffect } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse';

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'


const step3Schema = v.pipe(
    v.object({
        interested_service: v.pipe(
            v.object({
                accounting_and_bookkeeping: v.pipe(v.boolean(), v.check((value) => value)),
                other: v.boolean(),
            }),
        ),
        other_interested: v.pipe(
            v.string(),
            v.trim()
        )
    }),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['other_interested'],
            ],
            (input) => {
                if (input.interested_service.other) {
                    if (!input.other_interested) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'other_interested',
        ]
    ),
)

function DormantStep3({ onSubmit, handleBack, title }) {

    // State
    const [showOther, setShowOther] = useState(false)

    // Hooks
    const { company } = useCompany()

    const {
        control: step3Control,
        handleSubmit: handleStep3Submit,
        formState: { errors: step3Errors },
        watch
    } = useForm({
        resolver: valibotResolver(step3Schema),
        defaultValues: {
            interested_service: company.interested_service,
            other_interested: company.other_interested
        }
    })

    useEffect(() => {
        if (
            watch('interested_service.other')
        ) {
            setShowOther(true)
        } else {
            setShowOther(false)
        }

    }, [watch('interested_service.other')])

    return (
        <form key={0} onSubmit={handleStep3Submit(onSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12} >
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <div className='flex flex-col mt-2'>
                        <Controller
                            name='interested_service.accounting_and_bookkeeping'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Dormant Company Accounting & Bookkeeping</span>} />
                                    {step3Errors.interested_service?.accounting_and_bookkeeping && <FormHelperText error>This filed is required.</FormHelperText>}
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.other'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Other Services? Please specify.</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showOther}>
                                            <Controller
                                                name='other_interested'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CustomTextField
                                                        {...field}
                                                        variant='text'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                )}
                                            />
                                            {step3Errors.other_interested && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default DormantStep3